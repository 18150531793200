"use strict";

// This script will look for common tracking methods from Google Analytics, Google Tag Manager and Facebook Pixel.
// Each tracking method detected will be send events from the HotDoc Online Bookings Widget.
// You can remove platforms you don't need by deleting the sectoin within the function below.

function sendHotDocEvent(eventAction, eventLabel) {
  // Google Analytics (gtag.js)
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  if (typeof gtag !== "undefined") {
    gtag("event", eventAction, {
      event_category: "HotDoc",
      event_label: eventLabel,
    });
  }

  // Google Analytics (analytics.js)
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  // "window.ga" is the default location for GA. If the GA object has been renamed, the standard GA snippet will
  // store the new name in "GoogleAnalyticsObject", so we check there too.
  var gaFunction = window.ga || window[window.GoogleAnalyticsObject];

  if (typeof gaFunction !== "undefined") {
    gaFunction("send", {
      hitType: "event",
      eventCategory: "HotDoc",
      eventAction: eventAction,
      eventLabel: eventLabel,
    });
  }

  // Google Tag Manager Event
  // https://developers.google.com/tag-manager/devguide
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({
      event: "HotDoc",
      gaEventAction: eventAction,
      gaEventLabel: eventLabel,
    });
  }

  // Facebook Tracking Pixel
  // Only tracks final event (patient-books-appointment) by default.
  // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
  if (
    eventAction === "patient-books-appointment" &&
    typeof fbq !== "undefined"
  ) {
    fbq("track", "Schedule");
  }
}

function receivedHotDocConversion(e) {
  // Get metadata from the event fired by the HotDoc Widget
  var event = e.detail;

  if (event.code === "patient-sees-loaded-widget") {
    sendHotDocEvent(event.code, event.meta.clinic);
  }

  if (event.code === "patient-starts-triage-questions") {
    sendHotDocEvent(event.code, event.meta.clinic);
  }

  if (event.code === "patient-books-appointment") {
    var eventLabel = [
      event.meta.clinic,
      event.meta.doctor,
      event.meta.reason,
      event.meta.patient,
    ].join(" - ");
    sendHotDocEvent(event.code, eventLabel);
  }
}

window.addEventListener("hotdocConversion", receivedHotDocConversion, false);
